<template>
  <div class="products">
    <div class="three-btns container mb-4">
      <button @click="$router.push('/')">{{ $t("Main") }} /</button>
      <button>{{ $t("research results") }}</button>
    </div>

    <!-- categories and recently arrived -->
    <div class="categories mt-11 mt-5 px-4">
      <div class="row">
        <div class="col-md-3 col-xs-12">
          <button @click="() => (showFilter = !showFilter)"
            class="p-2 rounded d-lg-none d-flex align-items-center gap-2">
            <span class="pi pi-filter"></span>
            <!-- <span>Filter</span> -->
          </button>
          <div class="sidebarsearch mt-2 p-3" v-if="showFilter">
            <div class="mb-3">
              <h3>{{ $t("Categories") }}</h3>
              <div class="field-checkbox mb-2" v-for="item of categoriesList" :key="'categories_' + item._id">
                <Checkbox :id="'categories_' + item._id" name="categoriesId" :value="item._id"
                  v-model="body.categoriesId" :inputId="'categories_' + item._id" />
                <label :for="'categories_' + item._id">{{
                  $getByLang(item.name)
                }}</label>
              </div>
            </div>
            <div class="mb-3">
              <h3>{{ $t("sections") }}</h3>
              <div class="field-checkbox mb-2" v-for="item of subcategoriesList" :key="'subcategories_' + item._id">
                <Checkbox :id="'subcategories_' + item._id" name="subCategoriesId" :value="item._id"
                  v-model="body.subCategoriesId" :inputId="'subcategories_' + item._id" />
                <label :for="'subcategories_' + item._id">{{
                  $getByLang(item.name)
                }}</label>
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("the size") }}</h3>
              <div class="field-checkbox mb-2" v-for="item of sizeList" :key="'size_' + item._id">
                <Checkbox :id="'size_' + item._id" name="sizeId" :value="item._id" v-model="body.sizes.sizeId"
                  :inputId="'size_' + item._id" />
                <label :for="'size_' + item._id">
                  {{ item.width }}W X {{ item.height }}H CM
                </label>
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("Colors") }}</h3>

              <span v-for="item of colorsList" :key="'colors_' + item._id">
                <input type="radio" class="btn-check" name="colorId" :id="'colors_' + item._id" autocomplete="off"
                  :value="item._id" v-model="body.colorsList.colorId" />
                <label class="btn btn-color" :for="'colors_' + item._id"
                  :style="'border-color: ' + item.value + ' !important'">
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.5" cy="11.5" r="11.5" :fill="item.value" />
                  </svg>
                </label>
              </span>
            </div>
            <div class="mb-3">
              <h3>{{ $t("the price") }}</h3>
              <div class="row mb-4">
                <div class="col-md-6">{{ Math.round(body.price[1]) }}{{ $t("SAR") }}</div>
                <div class="col-md-6 text-left">
                  {{ Math.round(body.price[0]) }}{{ $t("SAR") }}
                </div>
              </div>
              <div>
                <Slider v-model="body.price" :min="0" :max="100000" :range="true" />
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("resources") }}</h3>
              <div class="field-checkbox mb-2" v-for="item of materialsList" :key="'materials_' + item._id">
                <Checkbox :id="'materials_' + item._id" name="materialsId" :value="item._id" v-model="body.materialsId"
                  :inputId="'materials_' + item._id" />
                <label :for="'materials_' + item._id">
                  {{ $getByLang(item.name) }}
                </label>
              </div>
            </div>

            <div class="mb-3">
              <h3>{{ $t("the shop") }}</h3>
              <div class="field-checkbox mb-2" v-for="item of vendorsList" :key="'vendors_' + item._id">
                <Checkbox :id="'vendors_' + item._id" name="vendorsId" :value="item._id" v-model="body.vendorsId"
                  :inputId="'vendors_' + item._id" />
                <label :for="'vendors_' + item._id">
                  {{ item.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-xs-12">
          <div class="new m-2">
            <h1 class="cat-head mb-4">{{ $t("research results") }}</h1>
            <div style="min-height: 700px">
              <div class="m-5 text-center" v-if="list.length == 0">
                <img src="../../assets/images/notFoundIllustration.svg" class="mt-5" alt="" style="max-width: 300px" />
              </div>
              <div class="row" v-if="list.length > 0">
                <div class="col-6 col-md-3 col-lg-3 mb-3" v-for="item of list">

                  <div class="newCard" @click="$router.push('/product/' + item._id)">
                    <img src="../../assets/images/like.svg" class="like" :alt="$getByLang(item.name)" />
                    <span v-if="item.hasOffer == true" class="itemOfferRight">
                      {{ $t("rival") }}
                      {{ item.offer.offerValue }}%
                    </span>

                    <img class="logo" :src="item.colorsList.length > 0 &&
                      item.colorsList[0].colorsImage.length > 0
                      ? $baseUploadURL +
                      item.colorsList[0].colorsImage[0].name
                      : ''
                      " :alt="$getByLang(item.name)" />
                    <div class="newCard-body p-2">
                      <h5 class="text-truncate">{{ $getByLang(item.name) }}</h5>
                      <b-form-rating v-model="item.rate" variant="warning" class="mb-2" readonly inline no-border
                        size="sm" locale="ar-EG"></b-form-rating>
                      <span> ({{ item.rateAmount }}) </span>

                      <div class="price-container" v-if="item.sizes.length > 0">
                        <p class="current-price" :class="{ hidden: !item.hasOffer }">
                          {{
                            Math.round(item.sizes[0].mainPrice - 
           item.sizes[0].mainPrice * (item.hasOffer ? item.offer.offerValue / 100 : 0))

                          }}
                          {{ $t("r.s") }}
                        </p>
                        <p v-if="item.hasOffer" class="old-price">
                          <del>
                            {{  Math.round(item.sizes[0].mainPrice) }} {{ $t("r.s") }}
                          </del>
                        </p>
                        <p v-else class="single-price">
                          {{  Math.round(item.sizes[0].mainPrice) }} {{ $t("r.s") }}
                        </p>
                      </div>

                      <!-- <p v-if="item.hasOffer == false">{{ item.sizes[0].mainPrice }} {{ $t("SAR") }}</p>
                      <p v-if="item.hasOffer == true && item.sizes.length > 0">
                        {{
                          (item.sizes[0].mainPrice -
                          item.sizes[0].mainPrice * (item.offer.offerValue / 100)).toFixed(2)
                        }}
                        {{ $t("r.s") }}
                        <del class="offeroldprice">
                           {{ item.sizes.length > 0 ? item.sizes[0].mainPrice : 0 }}
                          {{ $t("r.s") }}
                        </del>
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <Paginator  :pageNumber="pageNum"
              v-if="list.length > 0"
              style="direction: ltr"
              :rows="limit"
              :totalRecords="totalItemsCount"
              @page="onPage($event)"
            ></Paginator> -->
            <infinite-loading @infinite="loadMore" :identifier="pageNum">
              <div slot="no-more">{{ $t('No more data') }}</div>
              <div slot="no-results">{{ $t('No results') }}</div>
            </infinite-loading>



          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    InfiniteLoading,
  },

  head: {
    title: function () {
      return {
        inner: 'بحث',
      }

    },
  },
  data() {
    return {
      pageNum:
        sessionStorage.homeHerePageSearch == "search"
          ? parseInt(sessionStorage.homeHerePageNumberSearch)
          : 1,
      body: {
        categoriesId: [],
        subCategoriesId: [],
        colorsList: {
          colorId: null
        },
        sizes: {
          sizeId: []
        },
        price: [15000, 77000],
        vendorsId: [],
        materialsId: []
      },
      categoriesList: [],
      subcategoriesList: [],
      sizeList: [],
      colorsList: [],
      vendorsList: [],

      materialsList: [],
      list: [],
      totalItemsCount: 0,

      limit: 0,
      page: 1,
      q: null,
      one: {
        categoriesId: {
          name: {
            ar: null,
            en: null
          }
        },
        name: {
          ar: null,
          en: null
        },
        bannerSubCategoryImage: null
      },
      showFilter: false
    };
  },
  mounted() {
    if (window.innerWidth > 992) {
      this.showFilter = true;
    }
  },
  methods: {
    $goToSave(link) {
      sessionStorage.setItem("homeHerePageSearch", "search");
      sessionStorage.setItem("homeHerePageNumberSearch", this.pageNum);
      this.$router.push(link);
    },
    onPage(event) {
      //event.page: New page number
      this.pageNum = event.page + 1;
      this.getData(event.page + 1);
      sessionStorage.setItem("homeHerePageSearch", "search");
      sessionStorage.setItem("homeHerePageNumberSearch", this.pageNum);
      window.scrollTo(0, 0);
    },
       async loadMore($state) {
        if (this.allDataLoaded) {
            $state.complete();
            return;
        }

        try {
            const res = await this.getData2(this.pageNum);

            if (res.length) {
                this.list = [...this.list, ...res];
                this.pageNum += 1; // زيادة رقم الصفحة
                $state.loaded();
            } else {
                this.allDataLoaded = true; // تعيين علامة التوقف عند تحميل كل البيانات
                $state.complete();
            }
        } catch (error) {
            console.error("Error loading more data:", error);
            $state.complete();
        }
    },
    async getData2(page = 1) {

      let obj = {};
      if (this.q) {
        obj = {
          q: this.q
        };
      }

      if (this.body.categoriesId.length > 0) {
        obj.categoriesId = { $in: this.body.categoriesId };
      }
      if (this.body.subCategoriesId.length > 0) {
        obj.subCategoriesId = { $in: this.body.subCategoriesId };
      }
      if (this.body.vendorsId && this.body.vendorsId.length > 0) {
        obj.vendorsId = { $in: this.body.vendorsId };
      }
      if (this.body.materialsId.length > 0) {
        obj.materialsId = { $in: this.body.materialsId };
      }
      if (this.body.sizes.sizeId.length > 0) {
        obj.sizes = {
          sizeId: { $in: this.body.sizes.sizeId }
        };
      }
      if (this.body.colorsList.colorId) {
        obj.colorsList = {
          colorId: this.body.colorsList.colorId
        };
      }

      if (this.body.price[0] != 15000 && this.body.price[1] != 77000) {
        obj.sizes.mainPrice = {
          $gte: this.body.price[0],
          $lte: this.body.price[1]
        };
      }


      const res = await this.$http.post(`items/search?limit=12&page=${page}`, obj);
      return res.data.docs;
    },

    getData(q, page = 1) {
      this.q = q;
      let obj = {};
      if (q) {
        obj = {
          q: q
        };
      }

      if (this.body.categoriesId.length > 0) {
        obj.categoriesId = { $in: this.body.categoriesId };
      }
      if (this.body.subCategoriesId.length > 0) {
        obj.subCategoriesId = { $in: this.body.subCategoriesId };
      }
      if (this.body.vendorsId && this.body.vendorsId.length > 0) {
        obj.vendorsId = { $in: this.body.vendorsId };
      }
      if (this.body.materialsId.length > 0) {
        obj.materialsId = { $in: this.body.materialsId };
      }
      if (this.body.sizes.sizeId.length > 0) {
        obj.sizes = {
          sizeId: { $in: this.body.sizes.sizeId }
        };
      }
      if (this.body.colorsList.colorId) {
        obj.colorsList = {
          colorId: this.body.colorsList.colorId
        };
      }

      if (this.body.price[0] != 15000 && this.body.price[1] != 77000) {
        obj.sizes.mainPrice = {
          $gte: this.body.price[0],
          $lte: this.body.price[1]
        };
      }


      this.$http.post(`items/search?limit=12&page=${this.pageNum}`, obj).then((res) => {
        this.list = res.data.docs;
        this.totalItemsCount = res.data.totalDocs;
        this.limit = res.data.limit;
      });
    }
  },
  created() {
    this.$http.get("categories?limit=1000000").then((res) => {
      this.categoriesList = res.data.docs;
    });
    this.$http.get("subcategories?limit=1000000").then((res) => {
      this.subcategoriesList = res.data.docs;
    });
    this.$http.get("sizes?limit=1000000").then((res) => {
      this.sizeList = res.data.docs;
    });
    this.$http.get("vendors?limit=1000000").then((res) => {
      this.vendorsList = res.data.docs;
    });

    this.$http.get("colors?limit=1000000").then((res) => {
      this.colorsList = res.data.docs;
    });
    this.$http.get("materials?limit=1000000").then((res) => {
      this.materialsList = res.data.docs;
    });

    this.getData(this.$route.query.q);
  },
  watch: {
    "$route.query.q"(val) {
      if (val) {
        this.getData(val);
      }
    },
    "body.categoriesId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "body.subCategoriesId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "body.vendorsId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "body.materialsId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },

    "body.sizes.sizeId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },

    "body.colorsList.colorId"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    },
    "this.body.price"(val) {
      if (val) {
        setTimeout(() => this.getData(), 100);
      }
    }
  }
};
</script>

<style scoped>
.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 50px;
  /* تحديد ارتفاع أدنى لضمان الاتساق */
}

.current-price.hidden {
  visibility: hidden;
  /* إخفاء السعر العلوي بدون إزالة مكانه */
}

.current-price {
  font-size: 24px;
  /* أو الحجم الذي تراه مناسبًا */
  font-weight: bold;
  color: #000;
  /* اللون الذي تريده للنص */
  margin: 0;
}

.single-price {
  font-size: 24px;
  /* أو الحجم الذي تراه مناسبًا */
  font-weight: bold;
  color: #000;
  /* اللون الذي تريده للنص */
  margin: 0;
}

.old-price {
  font-size: 18px;
  /* أو الحجم الذي تراه مناسبًا */
  color: #888;
  /* اللون الذي تريده للنص المشطوب */
  margin: 0;
}

.old-price del {
  color: #888;
  /* اللون الذي تريده للنص المشطوب */
}
</style>
